/** 
 * Slider Module
 * @description : Horizontally scrolling list of content cards
 */

(function () {
    var heroCarousel = new Swiper('.js-slider', {
        speed: 400,
        freeMode: true,
        scrollbar: {
            el: '.js-slider_module__scrollbar',
        },
        slidesPerView: 'auto',
        mousewheel: {
            forceToAxis: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        grabCursor: true
    });
})();


(function () {
    var ctaCarousel = new Swiper('.js-slider__cta-slide', {
        speed: 400,
        spaceBetween: 500,
        slidesPerView: 1,
        autoplay: {
            delay: 5000
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        grabCursor: true
    });
})();